import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Layout from "../components/layout/Index"
import Title from "../components/commons/page/title/Index"
import { withTrans } from "../i18n/withTrans"

const Index = ({ t }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [reset, setReset] = useState(false)
  const [notif, setNotif] = useState("")
  const [isSuccess, setIsSuccess] = useState("")
  const initialValues = {
    email: "",
    name: "",
    phone_number: "",
    message: "",
  }
  const { values, errors, handleChange, handleSubmit } = useForm(
    initialValues,
    submit_message,
    validate,
    reset
  )

  function submit_message() {
    setIsDisabled(true)
    let param = {
      body: values.message,
      from: values.email,
      handphone: values.phone_number,
      name: values.name,
    }
    const messageSend = t("contactUs.massageSend")
    const messageNotSend = t("contactUs.messageNotSend")
    fetch(`${process.env.GATSBY_API_URL}/contact_us/send_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(param),
    })
      .then(response => {
        setIsDisabled(false)
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Error")
        }
      })
      .then(result => {
        setReset(true)
        setIsSuccess("#009D57")
        setNotif(messageSend)
        hideInfo()
        setReset(false)
      })
      .catch(error => {
        setIsSuccess("red")
        setNotif(messageNotSend)
        hideInfo()
      })
  }

  const hideInfo = () => {
    window.setTimeout(() => {
      setNotif("")
    }, 2000)
  }

  return (
    <Layout>
      <div
        style={{
          backgroundImage:
            "linear-gradient(6deg, #00a650, #00a650 50%, #fff 50.2%, #fff )",
        }}
      >
        <Container className="contact-us">
          <Title
            style={{ margin: "0 0 20px", padding: "10px 0 !important" }}
            label={t("header.contactUs")}
          />
          <div className="message">
            <span style={{ color: "#9fa6b0" }}>
              {t("contactUs.contactUsMessage")}
            </span>
          </div>
          <Row className="mt-5">
            <Col md={8} s={12} className="shadow rounded bg-white mb-5">
              <form
                className="p-5"
                onSubmit={e => handleSubmit(e, t)}
                noValidate
                id="form-contact-us"
              >
                <div className="form-group row pb-4">
                  <label
                    htmlFor="name"
                    className="col-md-2 col-sm-12 col-form-label"
                    style={{ color: "#9fa6b0" }}
                  >
                    {t("contactUs.name")}
                  </label>
                  <div className="col-md-10 col-sm-12">
                    <input
                      type="text"
                      className={`form-control ${errors.name && "is-invalid"}`}
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      disabled={isDisabled}
                      required
                    />
                    {errors.name && (
                      <p style={{ fontSize: 12, color: "#de5252" }}>
                        {errors.name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group row pb-4">
                  <label
                    htmlFor="email"
                    className="col-md-2 col-sm-12 col-form-label"
                    style={{ color: "#9fa6b0" }}
                  >
                    E-mail
                  </label>
                  <div className="col-md-10 col-sm-12">
                    <input
                      type="text"
                      className={`form-control ${errors.email && "is-invalid"}`}
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      disabled={isDisabled}
                      required
                    />
                    {errors.email && (
                      <p style={{ fontSize: 12, color: "#de5252" }}>
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group row pb-4">
                  <label
                    htmlFor="phone_number"
                    className="col-md-2 col-sm-12 col-form-label"
                    style={{ color: "#9fa6b0" }}
                  >
                    {t("contactUs.phoneNumber")}
                  </label>
                  <div className="col-md-10 col-sm-12">
                    <input
                      type="text"
                      className={`form-control ${errors.email && "is-invalid"}`}
                      name="phone_number"
                      onChange={handleChange}
                      value={values.phone_number}
                      disabled={isDisabled}
                      required
                    />
                    {errors.phone_number && (
                      <p style={{ fontSize: 12, color: "#de5252" }}>
                        {errors.phone_number}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group row pb-4">
                  <label
                    htmlFor="message"
                    className="col-md-2 col-sm-12 col-form-label"
                    style={{ color: "#9fa6b0" }}
                  >
                    {t("contactUs.message")}
                  </label>
                  <div className="col-md-10 col-sm-12">
                    <textarea
                      type="text"
                      className={`form-control ${
                        errors.message && "is-invalid"
                      }`}
                      name="message"
                      onChange={handleChange}
                      value={values.message}
                      disabled={isDisabled}
                      required
                    />
                    {errors.message && (
                      <p style={{ fontSize: 12, color: "#de5252" }}>
                        {errors.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Button type="submit" disabled={isDisabled}>
                      {t("contactUs.sendMessage")}
                    </Button>
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold", color: isSuccess }}>
                      {notif}
                    </span>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

const validate = (values, t) => {
  let errors = {}
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!values.email) {
    errors.email = t("contactUs.emailReq")
  } else if (!regexEmail.test(values.email)) {
    errors.email = t("contactUs.emailNotValid")
  }
  if (!values.name) {
    errors.name = t("contactUs.nameReq")
  }
  if (!values.phone_number) {
    errors.phone_number = t("contactUs.phoneNumberReq")
  }
  if (!values.message) {
    errors.message = t("contactUs.messageReq")
  }

  return errors
}

const useForm = (initialValues, callback, validate, reset) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback()
      setIsSubmitting(false)
    }
  }, [errors])

  useEffect(() => {
    if (reset) {
      setValues(initialValues)
    }
  }, [reset])

  const handleSubmit = (e, t) => {
    console.log(t)
    if (e) e.preventDefault()
    if (validate) {
      setErrors(validate(values, t))
    }
    setIsSubmitting(true)
  }

  const handleChange = e => {
    e.persist()
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value,
    }))
  }

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
}

export default withTrans(Index)
